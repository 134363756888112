import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doUpdateCategory } from '@app/store/slices/categorySlice';
import { UpdateCategoryData } from '@app/api/category.api';
import { CategoryModel, SelectedVideosModel } from '@app/domain/CategoryModel';
import { Upload } from '@app/components/common/Upload/Upload';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Button } from '@app/components/common/buttons/Button/Button';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import noImage from 'assets/images/no-image.png';
import { Table } from 'components/common/Table/Table';
import { Pagination, VideoSelectTableRow, getVideoList } from '@app/api/video.api';
import { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import { v4 as uuidv4 } from 'uuid';

interface UpdateCategoryFormModalProps {
  data: CategoryModel;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

const initialInfoValues: CategoryModel = {
  id: -1,
  title: '',
  name: '',
  orderNo: -1,
  photo: '',
  videos: [],
};

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

export const UpdateCategoryFormModal: React.FC<UpdateCategoryFormModalProps> = ({ open, onCancel, onFinish, data }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [tableVideosData, setTableVideosData] = useState<{
    data: VideoSelectTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const [currentVideos, setCurrentVideos] = useState<SelectedVideosModel[]>(data.videos ?? []);

  const [selectedRowKeys, setSelectedRowKeys] = useState<{ [key: string]: number[] }>({});
  const [selectedVideos, setSelectedVideos] = useState<{ [key: string]: VideoSelectTableRow[] }>({});

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    await syncCurrentPageVideoToForm();
    form.submit();
  };

  const fetchVideos = useCallback(
    (pagination: Pagination) => {
      setTableVideosData((tableData) => ({ ...tableData, loading: true }));
      getVideoList({ page: pagination.current, limit: pagination.pageSize }).then((res) => {
        setTableVideosData({
          data: res.data.data,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
        });
        if (!selectedRowKeys[pagination.current ?? 0]) {
          // sync current page categories id of video to current state
          const _selectedVideos = currentVideos.filter((value) =>
            res.data.data.map((_value: VideoSelectTableRow) => _value.id).includes(value.id),
          );
          setSelectedRowKeys((value) => ({
            ...value,
            [`${pagination.current ?? 0}`]: _selectedVideos.map((value) => value.id),
          }));

          // unload selected cates to current state
          const _currentVides = currentVideos.filter(
            (value) => !_selectedVideos.map((_value) => _value.id).includes(value.id),
          );
          setCurrentVideos(_currentVides);
        }
      });
    },
    [selectedRowKeys, currentVideos],
  );

  useEffect(() => {
    fetchVideos(initialPagination);
  }, []);

  const handleSubmit = async (values: UpdateCategoryData) => {
    setLoading(true);
    const _file: File | undefined = values.files ? values.files.pop() : undefined;
    let photo = _file?.name ? _file?.name : data.photo;
    const _photo = (photo || '').split('/').pop();
    photo = decodeURI(_photo || '');
    // const _currentPageSelectedVideos = await syncCurrentPageVideoToForm();
    // const _selectedVideos = {
    //   ...selectedVideos,
    //   [`${tableVideosData.pagination.current ?? 0}`]: _currentPageSelectedVideos,
    // };
    const _videos: VideoSelectTableRow[] = [];
    Object.keys(selectedVideos).forEach((key) => {
      _videos.push(...selectedVideos[key]);
    });
    // console.log(_videos);
    dispatch(
      doUpdateCategory({ ...values, id: data.id, photo: photo !== '' ? `category/${photo}` : '', videos: _videos }),
    )
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Update category',
          description: 'Successfully',
        });
        onFinish();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const userFormValues = useMemo(
    () =>
      data
        ? {
            id: data.id,
            title: data.title,
            name: data.name,
            orderNo: data.orderNo,
          }
        : initialInfoValues,
    [data],
  );

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onUploadProgress: (event: any) => {},
    };
    const _uuid = uuidv4();
    fmData.append('file', file, `${_uuid}.${(file as Blob).name?.split('.').pop() ?? ''}`);
    fmData.append('folderId', 'category');
    fmData.append('mediaId', (fmData.get('file') as File).name);

    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}files/upload`, fmData, config);
      onSuccess('Ok');
      form.setFieldsValue({ thumbnail: res.data.path, files: [fmData.get('file')] });
    } catch (err) {
      onError({ err });
    }
  };

  const syncCurrentPageVideoToForm = async () => {
    const _videos = tableVideosData.data.filter((value) =>
      selectedRowKeys[`${tableVideosData.pagination.current ?? 0}`]?.includes(value.id),
    );
    setSelectedVideos((value) => ({ ...value, [`${tableVideosData.pagination.current ?? 0}`]: _videos }));
    return _videos;
    // form.setFieldsValue({ videos: selectedRows });
  };

  const handleTableChange = async (pagination: Pagination) => {
    await syncCurrentPageVideoToForm();
    fetchVideos(pagination);
  };

  const onSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys((value) => ({
      ...value,
      [`${tableVideosData.pagination.current ?? 0}`]: selectedRowKeys as number[],
    }));
  };

  const rowSelection: TableRowSelection<VideoSelectTableRow> = {
    selectedRowKeys: selectedRowKeys[`${tableVideosData.pagination.current ?? 0}`] ?? [],
    onChange: onSelectedRowKeysChange,
  };

  const columns: ColumnsType<VideoSelectTableRow> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      // sorter: (a: VideoSelectTableRow, b: VideoSelectTableRow) => a.title.localeCompare(b.title),
    },
  ];

  return (
    <Modal title="Update category" open={open} onOk={onOk} onCancel={onCancel} confirmLoading={isLoading}>
      <BaseForm
        initialValues={userFormValues}
        form={form}
        layout="vertical"
        name="updateCategoryForm"
        onFinish={handleSubmit}
      >
        <BaseForm.Item name="title" label="Title" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="orderNo" label="Order number" rules={[{ required: false }, { type: 'integer', min: 0 }]}>
          <InputNumber />
        </BaseForm.Item>
        <BaseForm.Item
          name="files"
          label="Icon"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          rules={[{ required: false }]}
        >
          <Upload accept="image/*" maxCount={1} customRequest={handleUpload}>
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item>
        <img
          style={{ marginBottom: 10 }}
          src={data.photo && data.photo !== '' ? data.photo : noImage}
          alt={data.name}
          width={84}
          height={84}
        />
        <BaseForm.Item label="Choose Videos" name="videos" rules={[{ required: false }]}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableVideosData.data}
            loading={tableVideosData.loading}
            pagination={tableVideosData.pagination}
            onChange={handleTableChange}
            // scroll={{ x: 800 }}
            bordered
            rowKey={(record) => record.id}
          />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
